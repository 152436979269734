<template>
    <div>
      <v-layout wrap justify-center align-center>
          <v-progress-linear
            v-if="!loaded"
            color="primary"
            indeterminate
            rounded
            height="4"
          ></v-progress-linear>
        </v-layout>
      <v-card class="mb-4 heightFull">
        <v-card-title>
          <span>Patient Info</span>
          <v-spacer></v-spacer>
          <v-btn @click="addPatient=true" class="primary-btn">
            <v-icon>mdi-plus</v-icon>New Patient</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                hide-details
                @keyup.native.enter="getPatients()"
              >
              <template v-slot:append>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="patients"
            :height="screenHeight"
            class="elevation-1"
          >
            <template v-slot:item.id="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ patients.map(function(x) {return x.id; }).indexOf(item.id) + 1 }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.name="{ item }">
              <v-list-item-content>
                <v-list-item-title ><v-btn text hide-details dense :href="`profile/${item.id}`">{{ item.last_name + " " + item.first_name + " " + item.middle_name }}</v-btn></v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.gender="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ genders.find(v=>v.id==item.gender).name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue" @click="editPatient(item)">mdi-pencil-box-outline</v-icon>
              <v-icon color="red" @click="deletePatient(item)">mdi-close-box-outline</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-pagination  v-model="page" :length="pages"></v-pagination>
      </v-card>
      <v-dialog
        v-model="addPatient"
        persistent
        max-width="60%"
        @keydown.esc="addPatient = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Add Patient</span>
            <v-spacer></v-spacer>
            <v-btn color="error" x-small fab @click="addPatient = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-flex class="px-4 ma-4">  
  
              <div class="d-flex justify-space-around">
                <v-row class="align-center">
                    <v-col cols="4">
                      <v-text-field v-model="patient.passport"
                          v-mask="'AA#######'"
                          outlined dense hide-details
                          required
                          label="Pasport seriyani kiriting"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          v-model="patient.born_date"
                          v-mask="'##.##.####'"
                          outlined dense hide-details 
                          label="Tug'ilgan sanani kiriting"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-btn class="mt-n6" dense hide-details color="primary" @click="getCitizen()">Izlash</v-btn>
                    </v-col>
                      <v-col cols="4">
                        
                        <v-text-field
                            v-model="patient.first_name"
                            outlined dense hide-details 
                            label="Ism"
                        >
                        </v-text-field>
                        </v-col>
                        <v-col cols="4">
                        <v-text-field
                            v-model="patient.last_name"
                            outlined dense hide-details 
                            label="Familiya"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="patient.middle_name"
                            outlined dense hide-details 
                            label="Otasining ismi"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="patient.jshshir"
                            outlined dense hide-details 
                            label="PINFL"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                            v-model="patient.gender"
                            :items="genders"
                            outlined dense hide-details 
                            item-text="name"
                            item-value="id"
                            label="Jinsi"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="patient.phone_number"
                            outlined dense hide-details 
                            label="Telefon raqami"
                        >
                        </v-text-field>
                      </v-col>
                      
                      <v-col cols="4">
                        <v-autocomplete
                            :items="countries"
                            v-model="patient.country_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Davlat"
                            @change="getRegions()"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            :items="regions"
                            v-model="patient.region_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Viloyat"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            :items="districts"
                            v-model="patient.district_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Tuman"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-textarea
                          rows="2"
                          v-model="patient.address"
                          outlined dense hide-details 
                          label="Manzil"
                        >
                        </v-textarea>
                      </v-col>
                      <!-- <v-col cols="6">
                        <v-text-field
                            v-model="patient.email"
                            outlined dense hide-details 
                            label="Email"
                        >
                        </v-text-field>
                      </v-col> -->
                      <v-col cols="4">
                        <v-autocomplete
                            :items="martial_statuses"
                            v-model="patient.martial_status_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Oilaviy holati"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            :items="social_statuses"
                            v-model="patient.social_status_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Ijtimoiy holati"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="selectedRecommendation"
                            outlined dense hide-details 
                            :items="recommendations"
                            item-text="name"
                            item-value="id"
                            label="Bizni qayerdan topdingiz?"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" v-if="selectedRecommendation==1">
                        <v-autocomplete
                            v-model="patient.referral_doctor_id"
                            :items="referral_doctors"
                            outlined dense hide-details 
                            item-text="text"
                            item-value="id"
                            label="Tavsiya qilgan doktor"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" v-if="selectedRecommendation!=1">
                        <v-text-field
                            v-model="patient.social_name"
                            outlined dense hide-details 
                            label="Nomi"
                        >
                        </v-text-field>
                      </v-col>
                </v-row>

              </div>

            </v-flex>
          </v-card-text>
  
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="savePatient()">Save</v-btn>
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { maxLength, required } from "vuelidate/lib/validators";
  import messagesMixin from "@/mixins/messagesMixin";
  import Swal from "sweetalert2";
  import moment from 'moment';
  
  export default {
    name: "Todos",
  
    mixins: [messagesMixin, validationMixin],
  
    data() {
      return {
        user: null,
        actions: null,
        addBalanceModal: false,
        addPatient: false,
        showBalanceStatus: false,
        balance: null,
        selectedRecommendation: null,
        loaded: true,
        allIncome: 0,
        allExpance: 0,
        search: '',
        page: 1,
        pages: 4, // Adjust the number of pages according to your data
        patients: [],
        headers: [
          { text: 'T/r', value: 'id' },
          { text: 'F.I.O', value: 'name' },
          { text: 'Tug`ilgan sana', value: 'born_date' },
          { text: 'Gender', value: 'gender' },
          { text: 'Phone Number', value: 'phone_number' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        genders: [
          {
            id: 1,
            name: 'Erkak'
          },
          {
            id: 2,
            name: 'Ayol'
          },
        ],
        patient: {
          passport: '',
          born_date: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          jshshir: '',
          gender: 1,
          phone_number: '',
          email: '',
          country_id: 1,
          region_id: '',
          district_id: '',
          address: '',
          martial_status_id: '',
          social_status_id: '',
          referral_doctor_id: '',
          social_name: '',
          money: {},
        },
        martial_statuses: [],
        social_statuses: [],
        countries: [],
        regions: [],
        districts: [],
        referral_doctors: [],
        editMode: false,
        recommendations: [
          {
            "id": '1',
            "name": 'Doktor'
          },
          {
            "id": '2',
            "name": 'Ijtimoiy tarmoqlar'
          },
          {
            "id": '3',
            "name": 'OAV'
          },
        ]
      };
    },
  
    mounted() {
      this.getPatients();
      this.registryInfos();
    },
    computed: {
      screenHeight() {
        return window.innerHeight - 350;
      },
    },
    methods: {
      getCitizen() {
    //    console.log(this.form.birthday)
        this.patient.passport = this.patient.passport.toUpperCase();
        this.$http
          .post("/get-citizen", {
            passport: this.patient.passport,
            birthday: this.patient.born_date
          })
          .then(({ data }) => {
            if(data.status==0){
              this.$router.push('profile/' + data.id);
            }
            else{
              
              this.items = data.data.passport;
              this.patient.first_name = this.items.name;
              this.patient.last_name = this.items.sur_name;
              this.patient.middle_name = this.items.patronymic_name;
              this.patient.jshshir = this.items.pinfl;
              this.patient.address = this.items.address;
              this.patient.region_id = this.regions.find(v=>v.id==this.items.region_id).id;
              this.patient.district_id = this.districts.find(v=>v.id==this.items.district_id).id;
            }
            console.log(this.patient.district_id);
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },

      savePatient() {
        if(!this.editMode){
          let date = this.patient.born_date.split('.');
          this.patient.born_date = `${date[2]}-${date[1]}-${date[0]}`;
        }
        this.patient.jshshir = this.patient.jshshir.toString();
        this.patient.passport = this.patient.passport.toUpperCase();
        this.$http
          .post("/patient-registry", this.patient)
          .then(({ data }) => {
            this.addPatient = false;
            this.showSuccessMessage(data.message, 3000);
            this.$router.push('profile/' + data.id);
            // this.getPatients();
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getPatients() {
        this.$http
          .post("/patients", {
            search: this.search
          })
          .then(({ data }) => {
            this.patients = data;
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
      editPatient(item){
        this.editMode = false;
        item.born_date = moment(item.born_date).format('DD.MM.YYYY');
        this.patient = Object.assign({}, item);
        this.addPatient = true;
      },
      newPatient(){
        this.addPatient = true;
        this.patient = [];
      },
      deletePatient(item) {
          Swal.fire({
          title: "Bemorni o'chirish",
          text: "Rostdan ham ushu bemorni o'chirmoqchimisiz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "O'chirish"
        }).then(result => {
          
          if (result.value) {
            this.$http
          .delete("/patient-delete/" + item.id)
          .then(({ data }) => {
            this.showSuccessMessage(data.message);
            this.getPatients();
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
          }
        });
        
      },
      registryInfos() {
        this.$http
          .get("/get-registry-infos")
          .then(({ data }) => {
            this.martial_statuses = data.martial;
            this.social_statuses = data.social;
            this.countries = data.country;
            this.regions = data.region;
            this.districts = data.district;
            this.districts = data.district;
            this.referral_doctors = data.referral_doctor.map(v=>{v.text=v.fullname + ' / ' + v.workplace; return v;});
            // console.log(data.data);
  
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getRegions() {
        this.$http
          .get("/get-region/" + this.patient.country_id)
          .then(({ data }) => {
            this.regions = data;
            // this.social_statuses = data.social;
            // console.log(data.data);
  
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getDistricts() {
        this.$http
          .get("/get-district/" + this.patient.region_id)
          .then(({ data }) => {
            this.districts = data;
            // this.social_statuses = data.social;
            // console.log(data.data);
  
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
  
    },
  };
  </script>
  
  <style lang="css" scoped>
  .input-errors >>> .v-input__control > .v-input__slot:before {
    border-color: #f56c6c !important;
  }
  .income-text {
    color: rgba(47, 195, 195, 0.721);
  }
  .expance-text {
    color: red;
  }
  .v-card {
  margin-top: 20px;
  background-color: #ffffff;
}

.v-card-title {
  background-color: #f5f5f5;
}

.v-btn {
  background-color: #1976D2;
  color: #ffffff;
}

.v-text-field {
  margin-bottom: 20px;
}

.v-data-table-header {
  background-color: #f5f5f5;
}
.heightFull {
  height: 100% !important;
  background: #fff;
}
  </style>
  