<template>
    <div>
      <v-layout wrap justify-center align-center>
          <v-progress-linear
            v-if="!loaded"
            color="primary"
            indeterminate
            rounded
            height="4"
          ></v-progress-linear>
        </v-layout>
      <v-card class="mb-4 heightFull">
        <v-card-title>
          <span>Doctor Info</span>
          <v-spacer></v-spacer>
          <v-btn @click="newDoctor()" class="primary-btn">
            <v-icon>mdi-plus</v-icon>New Doctor</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                hide-details
                @keyup.native.enter="getDoctors()"
              >
              <template v-slot:append>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="doctors"
            :height="screenHeight"
            class="elevation-1"
          >
            <template v-slot:item.id="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ doctors.map(function(x) {return x.id; }).indexOf(item.id) + 1 }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.name="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.last_name + " " + item.first_name + " " + item.middle_name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.gender="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ genders.find(v=>v.id=item.gender).name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue" @click="editDoctor(item)">mdi-pencil-box-outline</v-icon>
              <v-icon color="red" @click="deleteDoctor(item)">mdi-close-box-outline</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-pagination  v-model="page" :length="pages"></v-pagination>
      </v-card>
      <v-dialog
        v-model="addDoctor"
        persistent
        max-width="60%"
        @keydown.esc="addDoctor = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Add Doctor</span>
            <v-spacer></v-spacer>
            <v-btn color="error" x-small fab @click="addDoctor = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-flex class="px-4 ma-4">  
  
              <div class="d-flex justify-space-around">
                <v-row class="align-center">
                    <v-col cols="4">
                      <v-text-field v-model="doctor.passport"
                          v-mask="'AA#######'"
                          outlined dense hide-details
                          required
                          label="Pasport seriyani kiriting"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          v-model="doctor.born_date"
                          v-mask="'##.##.####'"
                          outlined dense hide-details 
                          label="Tug'ilgan sanani kiriting"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-btn class="mt-n6" color="primary" dense hide-details @click="getCitizen()">Izlash</v-btn>
                    </v-col>
                      <v-col cols="4">
                        
                        <v-text-field
                            v-model="doctor.first_name"
                            outlined dense hide-details 
                            label="Ism"
                        >
                        </v-text-field>
                        </v-col>
                        <v-col cols="4">
                        <v-text-field
                            v-model="doctor.last_name"
                            outlined dense hide-details 
                            label="Familiya"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="doctor.middle_name"
                            outlined dense hide-details 
                            label="Otasining ismi"
                        >
                        </v-text-field>
                      </v-col>
                      <!-- <v-col cols="6">
                        <v-textarea
                          rows="2"
                          v-model="doctor.address"
                          outlined dense hide-details 
                          label="Manzil"
                        >
                        </v-textarea>
                      </v-col> -->
                      <v-col cols="4">
                        <v-select
                            v-model="doctor.gender"
                            :items="genders"
                            outlined dense hide-details 
                            item-text="name"
                            item-value="id"
                            label="Jinsi"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="doctor.phone_number"
                            outlined dense hide-details 
                            label="Telefon raqami"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                            v-model="doctor.branch"
                            outlined dense hide-details 
                            label="Ish joyi"
                        >
                        </v-text-field>
                      </v-col>
                      
                      

                      <v-col cols="4">
                        <v-autocomplete
                            :items="specializations"
                            v-model="doctor.specialization_id"
                            outlined dense hide-details
                            item-text="name"
                            item-value="id" 
                            label="Mutaxassisligi"
                        >
                        </v-autocomplete>
                      </v-col>
                </v-row>

              </div>

            </v-flex>
          </v-card-text>
  
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="saveDoctor()">Save</v-btn>
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { maxLength, required } from "vuelidate/lib/validators";
  import messagesMixin from "@/mixins/messagesMixin";
  import moment from 'moment';
  import Swal from "sweetalert2";
  
  export default {
    name: "Todos",
  
    mixins: [messagesMixin, validationMixin],
  
    data() {
      return {
        user: null,
        actions: null,
        addDoctor: false,
        loaded: true,
        search: '',
        page: 1,
        pages: 4, // Adjust the number of pages according to your data
        doctors: [],
        headers: [
          { text: 'T/r', value: 'id' },
          { text: 'F.I.O', value: 'name' },
          { text: 'Tug`ilgan sana', value: 'born_date' },
          { text: 'Jinsi', value: 'gender' },
          { text: 'Phone Number', value: 'phone_number' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        genders: [
          {
            id: 1,
            name: 'Erkak'
          },
          {
            id: 2,
            name: 'Ayol'
          },
        ],
        doctor: {
          passport: '',
          born_date: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          gender: 1,
          phone_number: '',
          branch: '',
          address: '',
          specialization_id: '',
        },
        specializations: [],
        editMode: false,
      };
    },
  
    mounted() {
      this.getDoctors();
      this.registryInfos();
    },
    computed: {
      screenHeight() {
        return window.innerHeight - 350;
      },
    },
    methods: {
      getCitizen() {
    //    console.log(this.form.birthday)
        this.doctor.passport = this.doctor.passport.toUpperCase();
        this.$http
          .post("/get-citizen", {
            passport: this.doctor.passport,
            birthday: this.doctor.born_date
          })
          .then(({ data }) => {
            this.items = data.data.passport;
            this.doctor.first_name = this.items.name;
            this.doctor.last_name = this.items.sur_name;
            this.doctor.middle_name = this.items.patronymic_name;
            // this.address = this.items.address;

          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      newAction() {
        this.money = {
          id: Date.now(),
          actiontype: null,
          date: null,
          amount: null,
        };
        this.addDoctor = true;
      },
      saveDoctor() {
        if(!this.editMode){
          let date = this.doctor.born_date.split('.');
          this.doctor.born_date = `${date[2]}-${date[1]}-${date[0]}`;
        }
        this.$http
          .post("/doctor-registry", this.doctor)
          .then(({ data }) => {
            this.addDoctor = false;
            this.getDoctors();
            this.editMode = false;
            this.showSuccessMessage(data.message, 3000);
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
      editDoctor(item){
        this.editMode = false;
        item.born_date = moment(item.born_date).format('DD.MM.YYYY');
        this.doctor = Object.assign({}, item);
        this.addDoctor = true;
      },
      newDoctor(){
        this.addDoctor = true;
        this.doctor = {
          passport: '',
          born_date: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          gender: 1,
          phone_number: '',
          branch: '',
          address: '',
          specialization_id: '',
        };
      },
      getDoctors() {
        this.$http
          .post("/doctors", {
            search: this.search
          })
          .then(({ data }) => {
            this.doctors = data;
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
  
      deleteDoctor(item) {
        Swal.fire({
          title: "Doktorni o'chirish",
          text: "Rostdan ham ushu doktorni o'chirmoqchimisiz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "O'chirish"
        }).then(result => {
          
          if (result.value) {
            this.$http
          .delete("/doctor-delete/" + item.id)
          .then(({ data }) => {
            this.showSuccessMessage(data.message);
            this.getDoctors();
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
          }
        });
      },
      registryInfos() {
        this.$http
          .get("/get-doctor-registry-infos")
          .then(({ data }) => {
            this.specializations = data.specialization;
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getRegions() {
        this.$http
          .get("/get-region/" + this.country_id)
          .then(({ data }) => {
            this.regions = data;
            // this.social_statuses = data.social;
            // console.log(data.data);
  
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getDistricts() {
        this.$http
          .get("/get-district/" + this.region_id)
          .then(({ data }) => {
            this.districts = data;
            // this.social_statuses = data.social;
            // console.log(data.data);
  
            // this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
  
    },
  };
  </script>
  
  <style lang="css" scoped>
  .input-errors >>> .v-input__control > .v-input__slot:before {
    border-color: #f56c6c !important;
  }
  .income-text {
    color: rgba(47, 195, 195, 0.721);
  }
  .expance-text {
    color: red;
  }
  .v-card {
  margin-top: 20px;
  background-color: #ffffff;
}

.v-card-title {
  background-color: #f5f5f5;
}

.v-btn {
  background-color: #1976D2;
  color: #ffffff;
}

.v-text-field {
  margin-bottom: 20px;
}

.v-data-table-header {
  background-color: #f5f5f5;
}
.heightFull {
  height: 100% !important;
  background: #fff;
}
  </style>
  