<template>
    <div>
      <v-layout wrap justify-center align-center>
          <v-progress-linear
            v-if="!loaded"
            color="primary"
            indeterminate
            rounded
            height="4"
          ></v-progress-linear>
        </v-layout>
      <v-card class="mb-4 heightFull">
        <v-card-title>
          <span>Services Info</span>
          <v-spacer></v-spacer>
          <v-btn @click="newService()" class="primary-btn">
            <v-icon>mdi-plus</v-icon>New Service</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                hide-details
                @keyup.native.enter="getServices()"
              >
              <template v-slot:append>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="services"
            :height="screenHeight"
            class="elevation-1"
          >
            <template v-slot:item.id="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ services.map(function(x) {return x.id; }).indexOf(item.id) + 1 }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.name="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.gender="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ genders.find(v=>v.id=item.gender).name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue" @click="editService(item)">mdi-pencil-box-outline</v-icon>
              <v-icon color="red" @click="deleteService(item)">mdi-close-box-outline</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-pagination  v-model="page" :length="pages"></v-pagination>
      </v-card>
      <v-dialog
        v-model="addService"
        persistent
        max-width="40%"
        @keydown.esc="addService = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Add Service</span>
            <v-spacer></v-spacer>
            <v-btn color="error" x-small fab @click="addService = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-flex class="px-4 ma-4">  
  
              <div class="d-flex justify-space-around">
                <v-row class="align-center">
                    
                      <v-col cols="6">
                        
                        <v-text-field
                            v-model="service.name"
                            outlined dense hide-details 
                            label="Nomi"
                        >
                        </v-text-field>
                        </v-col>
                        <v-col cols="6">
                        <v-text-field
                            v-model="service.cost"
                            outlined dense hide-details 
                            label="Narxi"
                        >
                        </v-text-field>
                      </v-col>
                      
                </v-row>

              </div>

            </v-flex>
          </v-card-text>
  
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="saveService()">Save</v-btn>
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { maxLength, required } from "vuelidate/lib/validators";
  import messagesMixin from "@/mixins/messagesMixin";
  import moment from 'moment';
  import Swal from "sweetalert2";
  
  export default {
    name: "Todos",
  
    mixins: [messagesMixin, validationMixin],
  
    data() {
      return {
        user: null,
        actions: null,
        addService: false,
        loaded: true,
        search: '',
        page: 1,
        pages: 4, // Adjust the number of pages according to your data
        services: [],
        headers: [
          { text: 'T/r', value: 'id' },
          { text: 'Nomi', value: 'name' },
          { text: 'Narxi', value: 'cost' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        service: {
          name: '',
          cost: '',
        },
        editMode: false,
      };
    },
  
    mounted() {
      this.getServices();
    },
    computed: {
      screenHeight() {
        return window.innerHeight - 350;
      },
    },
    methods: {
      newAction() {
        this.addService = true;
      },
      saveService() {
        this.$http
          .post("/service-registry", this.service)
          .then(({ data }) => {
            this.addService = false;
            this.getServices();
            this.editMode = false;
            this.showSuccessMessage(data.message, 3000);
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
      editService(item){
        this.editMode = false;
        this.service = Object.assign({}, item);
        this.addService = true;
      },
      newService(){
        this.addService = true;
        this.service = {
          name: '',
          cost: '',
        };
      },
      getServices() {
        this.$http
          .post("/services", {
            search: this.search
          })
          .then(({ data }) => {
            this.services = data;
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
  
      deleteService(item) {
        Swal.fire({
          title: "Xizmatni o'chirish",
          text: "Rostdan ham ushu xizmatni o'chirmoqchimisiz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "O'chirish"
        }).then(result => {
          
          if (result.value) {
            this.$http
          .delete("/service-delete/" + item.id)
          .then(({ data }) => {
            this.showSuccessMessage(data.message);
            this.getServices();
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
          }
        });
      },
  
    },
  };
  </script>
  
  <style lang="css" scoped>
  .input-errors >>> .v-input__control > .v-input__slot:before {
    border-color: #f56c6c !important;
  }
  .income-text {
    color: rgba(47, 195, 195, 0.721);
  }
  .expance-text {
    color: red;
  }
  .v-card {
  margin-top: 20px;
  background-color: #ffffff;
}

.v-card-title {
  background-color: #f5f5f5;
}

.v-btn {
  background-color: #1976D2;
  color: #ffffff;
}

.v-text-field {
  margin-bottom: 20px;
}

.v-data-table-header {
  background-color: #f5f5f5;
}
.heightFull {
  height: 100% !important;
  background: #fff;
}
  </style>
  