var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","align-center":""}},[(!_vm.loaded)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"4"}}):_vm._e()],1),_c('v-card',{staticClass:"mb-4 heightFull"},[_c('v-card-title',[_c('span',[_vm._v("Services Info")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary-btn",on:{"click":function($event){return _vm.newService()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("New Service")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","hide-details":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getServices()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.services,"height":_vm.screenHeight},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.services.map(function(x) {return x.id; }).indexOf(item.id) + 1))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.genders.find(function (v){ return v.id=item.gender; }).name))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.editService(item)}}},[_vm._v("mdi-pencil-box-outline")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteService(item)}}},[_vm._v("mdi-close-box-outline")])]}}])})],1),_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"40%"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.addService = false}},model:{value:(_vm.addService),callback:function ($$v) {_vm.addService=$$v},expression:"addService"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Service")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","x-small":"","fab":""},on:{"click":function($event){_vm.addService = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-flex',{staticClass:"px-4 ma-4"},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Nomi"},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Narxi"},model:{value:(_vm.service.cost),callback:function ($$v) {_vm.$set(_vm.service, "cost", $$v)},expression:"service.cost"}})],1)],1)],1)])],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.saveService()}}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }