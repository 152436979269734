<template>
  <div>
    <v-layout wrap justify-center align-center>
      <v-flex xs12 sm8 md6 class="px-4">
        <div class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <h2
            v-if="showBalanceStatus"
            class="text-center mr-5 font-weight-bold light-blue--text text--darken-4 my-4"
          >
            {{ user.balance }}
          </h2>
          <h2
            v-if="!showBalanceStatus"
            class="text-center mr-5 font-weight-light blue-grey--text text--darken-3 my-4"
          >
            ******
          </h2>
          <v-icon
            v-if="showBalanceStatus"
            large
            color="success"
            @click="showBalanceStatus = !showBalanceStatus"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-if="!showBalanceStatus"
            large
            color="error"
            @click="showBalanceStatus = !showBalanceStatus"
          >
            mdi-eye-off
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon color="primary" large @click="newAction()"> mdi-plus </v-icon>
        </div>

        <v-progress-linear
          v-if="!loaded"
          color="primary"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>

        <div class="d-flex justify-space-around">
          <div class="d-flex flex-column align-center">
            <p class="income-text mb-n1" style="font-size: 32px">
              + {{ allIncome }} so'm
            </p>
            <p class="income-text">Kirim</p>
          </div>
          <div class="d-flex flex-column align-center">
            <p class="expance-text mb-n1" style="font-size: 32px">
              - {{ allExpance }} so'm
            </p>
            <p class="expance-text">Chiqim</p>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-center">
          <v-card
            class="ma-2"
            max-width="225"
            outlined
            v-for="(todo, $index) in actions"
            :key="todo.id"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 d-flex">
                  <span>{{ todo.date }}</span>
                  <v-spacer></v-spacer>
                  <span>Category</span>
                </div>
                <div class="d-flex flex-column">
                  <v-list-item-title
                    class="text-h5 mb-1 mx-auto"
                    v-if="todo.type == 0"
                  >
                    - {{ todo.amount }} so'm
                  </v-list-item-title>
                  <v-list-item-title
                    class="text-h5 mb-1 mx-auto income-text"
                    v-if="todo.type == 1"
                  >
                    + {{ todo.amount }} so'm
                  </v-list-item-title>
                  <v-list-item-subtitle class="mx-auto">{{
                    todo.description
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn outlined rounded text color="primary" @click="edit(todo)">
                Edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                rounded
                text
                color="error"
                @click="deleteAction(todo)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        </v-flex>
    </v-layout>
    <v-dialog
      v-model="addBalanceModal"
      persistent
      max-width="450px"
      @keydown.esc="addBalanceModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Balance</span>
          <v-spacer></v-spacer>
          <v-btn color="error" x-small fab @click="addBalanceModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0" cols="12">
                <v-text-field v-model="balance" label="Amount"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="setBalance()">Save</v-btn>
          <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addMoneyModal"
      persistent
      max-width="450px"
      @keydown.esc="addMoneyModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Action</span>
          <v-spacer></v-spacer>
          <v-btn color="error" x-small fab @click="addMoneyModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0" cols="12">
                <v-select
                  v-model="money.actiontype"
                  :items="actiontypes"
                  label="Type"
                  solo
                ></v-select>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-text-field
                  v-model="money.description"
                  label="Description"
                  type="text"
                  solo
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-text-field
                  v-model="money.amount"
                  label="Amount"
                  type="text"
                  solo
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-text-field
                  v-model="money.date"
                  label="Action Date"
                  type="date"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="addAction()">Save</v-btn>
          <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import messagesMixin from "@/mixins/messagesMixin";

export default {
  name: "Todos",

  mixins: [messagesMixin, validationMixin],

  data() {
    return {
      user: null,
      actions: null,
      addBalanceModal: false,
      addMoneyModal: false,
      showBalanceStatus: false,
      balance: null,
      loaded: false,
      allIncome: 0,
      allExpance: 0,
      actiontypes: [
        {
          value: 0,
          text: "Expanse",
        },
        {
          value: 1,
          text: "income",
        },
      ],
      money: {},
    };
  },

  mounted() {
    this.getUser();
  },

  methods: {
    getUser() {
      this.allIncome = 0;
      this.allExpance = 0;
      this.$http
        .get("/currentUser")
        .then(({ data }) => {
          this.user = data[0];
          this.actions = data[0].actions;
          this.actions.filter((v) => {
            if (v.type == 1) {
              this.allIncome += parseInt(v.amount);
            } else {
              this.allExpance += parseInt(v.amount);
            }
          });
          this.loaded = true;
          if (this.user.balance == null) {
            this.addBalanceModal = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    setBalance() {
      this.$http
        .post("/setBalance", {
          balance: this.balance,
        })
        .then(({ data }) => {
          this.getUser();
          this.addBalanceModal = false;
          this.showSuccessMessage("Balance added successfully", 3000);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    newAction() {
      this.money = {
        id: Date.now(),
        actiontype: null,
        date: null,
        amount: null,
      };
      this.addMoneyModal = true;
    },
    addAction() {
      this.$http
        .post("/addAction", {
          money: this.money,
        })
        .then(({ data }) => {
          this.getUser();
          this.addMoneyModal = false;
          this.showSuccessMessage("Balance added successfully", 3000);
        })
        .catch((error) => {
          console.log(error);
          // this.showErrorMessage();
        });
    },

    deleteAction(todo) {
      this.$http
        .delete("/deleteAction/" + todo.id)
        .then(({ data }) => {
          this.getUser();

          this.showSuccessMessage("Action has been deleted");
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },

  },
};
</script>

<style lang="css" scoped>
.input-errors >>> .v-input__control > .v-input__slot:before {
  border-color: #f56c6c !important;
}
.income-text {
  color: rgba(47, 195, 195, 0.721);
}
.expance-text {
  color: red;
}
</style>
